(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["header"] = factory();
	else
		root["des"] = root["des"] || {}, root["des"]["header"] = factory();
})(window, function() {
return 